import { computed, onMounted, onUnmounted, ref } from "vue";

export const breakpoints = {
  "bp-custom": 320,
  "bp-mobile": 480,
  "bp-tablet": 768,
  "bp-desktop": 992,
  "bp-wide": 1200,
};
export const useBreakpoints = () => {
  const windowWidth = ref(window.innerWidth);

  const onWidthChange = () => (windowWidth.value = window.innerWidth);
  onMounted(() => window.addEventListener("resize", onWidthChange));
  onUnmounted(() => window.removeEventListener("resize", onWidthChange));

  const type = computed(() => {
    if (windowWidth.value < 320) return "xs";
    if (windowWidth.value >= 320 && windowWidth.value < 480) return "s";
    if (windowWidth.value >= 480 && windowWidth.value < 768) return "md";
    if (windowWidth.value >= 768 && windowWidth.value < 992) return "lg";
    if (windowWidth.value >= 992 && windowWidth.value < 1200) return "xlg";
    if (windowWidth.value >= 1200) return "w";
    return null; // This is an unreachable line, simply to keep eslint happy.
  });

  const width = computed(() => windowWidth.value);

  return { width, type };
};
