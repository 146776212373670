<script setup>
import ButtonShort from "@/components/ui/button-short.vue";
import router from "@/router";
import ContentContainer from "@/components/content-container.vue";
import logo from "@/assets/img/cuttedlogo.svg";
// import logo from "@/assets/img/quillart-quill.png";

const logoClick = () => {
  router.push("/");
};
</script>

<template>
  <div class="navbar-wrapper">
    <content-container>
      <div class="navbar">
        <img :src="logo" @click.prevent="logoClick" v-if="$route.path!=='/'" />
        <div v-else-if="true"></div>
<!--        <div></div>-->
        <button-short text="Задайте вопрос" @onClick="router.push('/contact')" />
      </div>
    </content-container>
  </div>
</template>

<style scoped lang="scss">
@import "@/styles/colors.scss";
@import "@/styles/helpers.scss";
@import "@/styles/typography.scss";

.navbar-wrapper {
  background: $primary;

  .navbar {
    background: $primary;
    height: toRem(73px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 toRem((43px/2));

    h1 {
      @include fancy-font();
      font-size: toRem(35px);
    }

    img {
      max-width: toRem(55px);
      aspect-ratio: 1/1;
      cursor: pointer;
    }
  }
}
</style>
