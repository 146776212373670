<template>
  <div class="app">
    <router-view />
  </div>
</template>

<style scoped lang="scss">
@import "@/styles/colors.scss";
.app {
  background: $typography-light;
}
</style>
