<script setup lang="ts"></script>

<template>
  <div class="container-wrapper">
    <div class="container">
      <slot />
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "@/styles/helpers.scss";
@import "@/styles/breakpoints.scss";
.container {
  margin: 0 auto;
  /* Custom, iPhone Retina */
  @media only screen and (min-width: $bp-custom) {
  }

  /* Extra Small Devices, Phones */
  @media only screen and (min-width: $bp-mobile) {
    max-width: $bp-mobile;
  }

  /* Small Devices, Tablets */
  @media only screen and (min-width: $bp-tablet) {
    max-width: $bp-tablet;
  }

  /* Medium Devices, Desktops */
  @media only screen and (min-width: $bp-desktop) {
    max-width: $bp-desktop;
  }

  /* Large Devices, Wide Screens */
  @media only screen and (min-width: $bp-wide) {
    max-width: $bp-wide;
  }
}
</style>
