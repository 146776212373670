import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import CruiseView from "@/views/meterials/CruiseView.vue";
import SilkCottonView from "@/views/meterials/SilkCottonView.vue";
import SilkTwillView from "@/views/meterials/SilkTwillView.vue";
import CottonSatinView from "@/views/meterials/CottonSatinView.vue";
import CottonVoileView from "@/views/meterials/CottonVoileView.vue";
import CottonTwillView from "@/views/meterials/CottonTwillView.vue";
import InteriorVelourView from "@/views/meterials/InteriorVelourView.vue";
import ContactUsView from "@/views/ContactUsView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Quillart",
    component: HomeView,
  },
  {
    path: "/contact",
    name: "Contact us",
    component: ContactUsView,
  },
  {
    path: "/material/cruise",
    name: "Quillart - Cruise",
    component: CruiseView,
  },
  {
    path: "/material/cottonsilk",
    name: "Quillart - Cotton-Silk",
    component: SilkCottonView,
  },
  {
    path: "/material/silktwill",
    name: "Quillart - Silk twill",
    component: SilkTwillView,
  },
  {
    path: "/material/cottonsatin",
    name: "Quillart - Cotton satin",
    component: CottonSatinView,
  },
  {
    path: "/material/cottonvoile",
    name: "Quillart - Cotton Voile",
    component: CottonVoileView,
  },
  {
    path: "/material/cottontwill",
    name: "Quillart - Cotton Twill",
    component: CottonTwillView,
  },
  {
    path: "/material/interiorvelour",
    name: "Quillart - Interior Velour",
    component: InteriorVelourView,
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = <string>to.name;
  next();
});

export default router;
