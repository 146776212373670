const importAll = (require: any) => {
  const images: Array<ImageResource> = [];
  require
    .keys()
    .forEach((key: string) =>
      images.push({ pathLong: require(key), pathShort: key })
    );
  const resultImages = images.sort(function (a, b) {
    const aDigit = parseInt(a.pathShort.replace(/^\D+/g, ""));
    const bDigit = parseInt(b.pathShort.replace(/^\D+/g, ""));

    return aDigit < bDigit ? -1 : aDigit > bDigit ? 1 : 0;
  });
  console.log(resultImages);
  return resultImages;
};

interface ImageResource {
  pathLong: string;
  pathShort: string;
}

export { importAll };
export type { ImageResource };
